import React from "react";
import { Link } from "react-router-dom";
import NavbarLinks from "./NavbarLinks";

const Navbar = () => {  
  return (
    <div className="navbar">
      <div className="navtitle">
        <Link to="/">joerickard.</Link>
        </div>
      <NavbarLinks></NavbarLinks>
    </div>
  );
};

export default Navbar;
