import React from "react";
import ReactPlayer from "react-player";

const Music = () => {
  return (
    <>
      <div class="title">
        <h2 class="heading">music.</h2>
      </div>
      <div className="music-content">
        Classically trained on the piano and cornet, I've been playing and
        making music virtually since I could walk. In the past few years, I've
        also been producing music electronically under the artist name Kalekdan.
        <br />
        <br />
        I've worked with various styles, from electronic genres such as lofi
        hip-hop and drum & bass, to orchestrating classical scores for games and
        film.
        <br />
        <br />
        Just a few of my favourite pieces so far are below:
        <div>
          <h2 class="sub-heading">Soaring.</h2>
          An orchestral piece I created to hopefully translate the emotion of
          soaring through the skies.
          <ReactPlayer
            url="https://soundcloud.com/kalekdan/soaring"
            width="100%"
            height="10%"
          />
        </div>
        <div>
          <h2 class="sub-heading">Elend's Theme.</h2>
          As a fan service to Brandon Sandersons incredible fantasy series
          <i> Mistborn</i>, I created some musical themes for some of my
          favourite characters. This is the theme I created for Elend.
          <ReactPlayer
            url="https://soundcloud.com/kalekdan/elends_theme"
            width="100%"
            height="10%"
          />
        </div>
        <div>
          <h2 class="sub-heading">Neon Wave.</h2>
          What would it sounds like if D&B utilised samples from Back to the
          Future? I asked myself that and created Neon Wave.
          <ReactPlayer
            url="https://soundcloud.com/kalekdan/neon-wave"
            width="100%"
            height="10%"
          />
        </div>
        <div>
          <h2 class="sub-heading">A Chance Encounter.</h2>
          If anyone is writing a rom-com, this is the track for you. I'd written
          it with a 'meet-cute' type scene in mind, where our characters first
          interact and explore their new relationship.
          <ReactPlayer
            url="https://soundcloud.com/kalekdan/a-chance-encounter"
            width="100%"
            height="10%"
          />
        </div>
      </div>
    </>
  );
};

export default Music;
