import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const NavbarLinks = () => {
  const navigate = useNavigate();
  const setCheckboxFalse = (e, to) => {
    e.preventDefault();
    var checkbox = document.getElementById("nav-check");
    navigate(to);
    checkbox.checked = false;
  };
  return (
    <>
      {/* When burger bar is checked (clicked), styling changes to show menu */}
      <input type="checkbox" id="nav-check" />
      <div class="burger-menu-btn">
        <label for="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      <div className="navlinks">
        <Link onClick={(event) => setCheckboxFalse(event, "/music")}>
          music.
        </Link>
        <Link onClick={(event) => setCheckboxFalse(event, "/software")}>
          software.
        </Link>
        <Link onClick={(event) => setCheckboxFalse(event, "/games")}>
          games.
        </Link>
        <Link onClick={(event) => setCheckboxFalse(event, "/academia")}>
          academia.
        </Link>
      </div>
    </>
  );
};

export default NavbarLinks;
