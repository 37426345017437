import React from "react";
import final_project from "../media/25007869_Final-Project-Report.pdf";

const Academia = () => {
  return (
    <>
      <div class="title">
        <h2 class="heading">academia.</h2>
      </div>
      <div className="academia-content">
        <object
          className="final-project-pdf"
          data={final_project}
          type="application/pdf"
        >
          <p>
            An Analysis of Natural Language Processing Techniques Applied to
            Generating Tweets -
            <a href="../media/25007869_Final-Project-Report.pdf">Link to PDF</a>
          </p>
        </object>
        <div className="content">
          <h3>
            An Analysis of Natural Language Processing Techniques Applied to
            Generating Tweets
          </h3>
          As my final project for my degree, I investigated the ability for
          recurrent neural networks (RNNs) to generate text based on a
          relatively small set of training data, namely the tweets of an
          individual user. Below is the full report where I detailed my
          findings.
        </div>
      </div>
    </>
  );
};

export default Academia;
