import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";
import Navbar from "./components/Navbar";
import Academia from "./components/Academia";
import Music from "./components/Music";
import Software from "./components/Software";
import Games from "./components/Games";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route path="/music" element={<Music />}></Route>
          <Route path="/software" element={<Software />}></Route>
          <Route path="/games" element={<Games />}></Route>
          <Route path="/academia" element={<Academia />}></Route>
          <Route path="/" element={<Homepage />}></Route>
        </Routes>
      </Router>

      <Footer></Footer>
    </div>
  );
}

export default App;
