import React from "react";
import Chip from "@mui/material/Chip";
// TODO: replace images with better images
import mani from "../media/software/mani.png";
import plogger from "../media/software/plogger.png";
import lang4j from "../media/software/lang4j.png";
import musicblender from "../media/software/musicblender.png";

const Software = () => {
  return (
    <>
      <div class="title">
        <h2 class="heading">software.</h2>
      </div>
      <div className="software-content">
        {/* TODO: Add a flag next to each as to whether it is still actively being developed */}
        <div className="software-element">
          <h2>Máni Language</h2>
          <img alt="Mani" src={mani}></img>
          <Chip
            className="software-status-chip"
            label="abandoned"
            sx={{ bgcolor: "#FF5733", color: "white" }}
          />
          <br />
          Majority contributer to a community project to build a new programming
          language, designed to be easy to learn and to teach programming
          concepts. Personally built the Máni 'compiler'.
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/mani-language/Mani">
            <u>here.</u>
          </a>
        </div>
        <div className="software-element">
          <h2>Tabletop Buddy</h2>
          <Chip
            className="software-status-chip"
            label="active development"
            sx={{ bgcolor: "orange", color: "white" }}
          />
          <br />
          Tabletop Buddy is a web tool for creating and managing character
          sheets for TTRPGs, and in particular 'Daggerheart'. It also provides
          various tools which are used to assist in the running of a TTRPG
          session, including random name generators, statblocks and notes.
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/JamesIAm/ttBuddy">
            <u>here.</u>
          </a>
        </div>
        <div className="software-element">
          <h2>PixoleLogger</h2>
          <img alt="plogger" src={plogger}></img>
          <Chip
            className="software-status-chip"
            label="built"
            sx={{ bgcolor: "green", color: "white" }}
          />
          <br />
          PixoleLogger is a very barebones lightweight logging tool for Java.
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/Kalekdan/Pixole-Logger">
            <u>here.</u>
          </a>
          <div className="software-element">
            <h2>lang4j</h2>
            <img alt="lang4j" src={lang4j}></img>
            <Chip
              className="software-status-chip"
              label="built"
              sx={{ bgcolor: "green", color: "white" }}
            />
            <br />
            Barebones lightweight tool for java to add language translations.
            <br />
            Repo is available on GitHub{" "}
            <a href="https://github.com/Kalekdan/lang4j">
              <u>here.</u>
            </a>
          </div>
        </div>
        <div className="software-element">
          <h2>Music Blender</h2>
          <img alt="musicblender" src={musicblender}></img>
          <Chip
            className="software-status-chip"
            label="active development"
            sx={{ bgcolor: "orange", color: "white" }}
          />
          <br />
          Web app to play music from youtube overlayed in various scenes which
          can be switched between, and individually controlled.
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/Kalekdan/Pixole-Logger">
            <u>here.</u>
          </a>
        </div>
        <div className="software-element">
          <h2>Letterboxd Tools</h2>
          <Chip
            className="software-status-chip"
            label="active development"
            sx={{ bgcolor: "orange", color: "white" }}
          />
          <br />
          Set of scripts for letterboxd which scraped user data to find films
          common between watchlists
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/Kalekdan/letterboxd-tools">
            <u>here.</u>
          </a>
        </div>
        <div className="software-element">
          <h2>Simple Wiki</h2>
          <Chip
            className="software-status-chip"
            label="built"
            sx={{ bgcolor: "green", color: "white" }}
          />
          <br />
          Chrome extension to switch between the default wikipedia and its
          'Simple Wiki' counterpart
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/Kalekdan/SimpleWiki">
            <u>here.</u>
          </a>
        </div>
        <div className="software-element">
          <h2>Tradeup Buddy</h2>
          <Chip
            className="software-status-chip"
            label="built"
            sx={{ bgcolor: "green", color: "white" }}
          />
          <br />
          Tool to find csgo skin tradeups which have the highest chance for
          profitability.
          <br />
          Repo is available on GitHub{" "}
          <a href="https://github.com/Kalekdan/Tradeup-Buddy">
            <u>here.</u>
          </a>
        </div>
      </div>
    </>
  );
};

export default Software;
