import React from "react";
import squidshot from "../media/games/squidshot.png";
import untitled_metroidvania from "../media/games/untitledmetroidvania.png";
import rocketjump from "../media/games/rocketjump.png";
import ttrpg from "../media/games/ttrpg.png";

const Games = () => {
  return (
    <>
      <div class="title">
        <h2 class="heading">games.</h2>
      </div>
      <div className="games-content">
        <div className="games-element">
          <h2>Squid Shot</h2>
          <img alt="squidshot" src={squidshot}></img>
          <br />
          Squid Shot is a puzzle platformer, where the player must traverse a
          level using only a grapple hook. There is a limited number of grapples
          available per level, and of course some spikes and other hazards.
          <br />
          The game is available to play on my
          <a href="https://kalekdan.itch.io/squidshot">
            {" "}
            <u>itch.io</u>
          </a>
          .
        </div>
        <div className="games-element">
          <h2>Untitled Metroidvania</h2>
          <img alt="untitled metroidvania" src={untitled_metroidvania}></img>
          <br />
          Untitled Metroidvania is a game currently under development. It is a
          metroidvania where you play as a monster hunter who is trying to free
          all the monsters of their curse rather than kill them. It has a large
          focus on challenging platforming, and less of a focus on combat (think
          Ori and the Blind Forest rather than Hollow Knight). You can keep up
          to date with development on{" "}
          <a href="https://twitter.com/JoeRickardDev">
            <u>my Twitter</u>
          </a>{" "}
          or watch the regular devlogs on{" "}
          <a href="https://www.youtube.com/channel/UCjxSTM3z4n_7ctEmCbusaLQ">
            <u>my YouTube.</u>
          </a>
        </div>
        <div className="games-element">
          <h2>Rocket Jump</h2>
          <img alt="rocket jump" src={rocketjump}></img>
          <br />
          Rocket Jump is one of the first games I ever made. It is a simple 3d
          Platformer where you use the age old mechanic of rocket jumping to get
          around. It only has one level and a few bugs but it's cool to
          look back on and see where I've come from.
          <br />
          The game is available to play on my
          <a href="https://kalekdan.itch.io/squidshot">
            {" "}
            <u>itch.io</u>
          </a>
          .
        </div>
        <div className="games-element">
          <h2>TTRPGs</h2>
          <img alt="ttrpg map" src={ttrpg}></img>
          <br />
          I've also been homebrewing a world for a D&D campaign I'm running for
          my friends, in fact I have an entire wiki dedicated to that! If you
          want to check it out, it's available{" "}
          <a href="/wiki/doku.php?id=campaigns:the_isles_of_fate">
            <u>here</u>
          </a>
          . All the other campaigns we've run are also documented on the same
          wiki{" "}
          <a href="/wiki/doku.php">
            <u>here</u>
          </a>
          .
        </div>
      </div>
    </>
  );
};

export default Games;
