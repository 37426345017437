import React from "react";

const Title = () => {
  return (
    <div class="title">
      <h2 class="heading">joerickard.</h2>
      <h3 class="subheading">
        build.&nbsp;&nbsp;&nbsp;create.&nbsp;&nbsp;&nbsp;play.
      </h3>
    </div>
  );
};

export default Title;
