import React from "react";
import { FaGithub, FaLinkedin, FaSoundcloud } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <div class="footer">
      <a
        class="footer-icon"
        href="https://github.com/Kalekdan"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>
      <a
        class="footer-icon"
        href="https://linkedin.com/in/joe-rickard"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>
      <a
        class="footer-icon"
        href="https://soundcloud.com/kalekdan"
        target="_blank"
        rel="noreferrer"
      >
        <FaSoundcloud />
      </a>
      <a
        class="footer-icon"
        href="mailto:joerickard@hotmail.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        <MdEmail />
      </a>
    </div>
  );
};

export default Footer;
