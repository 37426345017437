import React from "react";
import Title from "./Title";
import profilePic from "../media/profile.jpg";

const Homepage = () => {
  return (
    <div>
      <Title></Title>
      <div className="homepage-content">
        <img
          className="profile-pic"
          src={profilePic}
          alt="Profile"
        ></img>
        <div className="content">
          <h3>About Me.</h3>
          I'm Joe Rickard, an Integration Lead Developer from the UK. With a 1st
          class BSc in Computer Science from the University of Reading, I have
          dedicated my career to developing and creating innovative solutions.
          Over the years, I've built a diverse portfolio that includes software,
          games, and music.
          <br /> <br />
          Professionally, I specialize in APIs and Integration, ensuring
          seamless connectivity and functionality across various platforms. I'm
          also expanding my expertise in cloud computing and actively
          participate in community projects to share knowledge and give back.
          <br /> <br />
          My passion lies in creating things that enhance people's lives, making
          everyday experiences better and more enjoyable. Explore my portfolio
          to see the projects I've worked on, and feel free to get in touch if
          you'd like to collaborate or learn more about my work.
        </div>
      </div>
    </div>
  );
};

export default Homepage;
